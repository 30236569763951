var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row custom-cards"},_vm._l((_vm.storageSpacesData),function(storageSpace,index){return _c('CustomCard',{key:index,attrs:{"className":'col-sm-6 col-md-4 col-lg-3',"title":storageSpace.storageSpaceNameCurrent,"description":storageSpace.fullCode,"imagePath":storageSpace.storageSpaceImagePath,"defaultImg":_vm.defaultImg,"index":++index + _vm.filterData.currentIndex}},[_c('li',[_c('router-link',{attrs:{"to":{
          name: _vm.storageSpacesMediaRoute,
          params: {
            mainToken: storageSpace.userToken,
            storageSpaceToken: storageSpace.storageSpaceToken,
          },
        },"title":_vm.$t('StorageSpaces.open')}},[_c('img',{attrs:{"src":require("@/assets/images/media.svg")}})])],1),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setStorageSpaceData(storageSpace);
          _vm.openBottomSheet('StorageSpaceInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('QR.modelName')},on:{"click":function($event){_vm.setStorageSpaceData(storageSpace);
          _vm.openBottomSheet('StorageSpaceQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.hasPrivilegeEdit)?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setStorageSpaceData(storageSpace);
          _vm.openBottomSheet('StorageSpaceUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.hasPrivilegeFinaleDelete)?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.StorageSpaceDelete",modifiers:{"StorageSpaceDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setStorageSpaceData(storageSpace)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(_vm.hasPrivilegeChangeActivationType)?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.StorageSpaceChangeActivationType",modifiers:{"StorageSpaceChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setStorageSpaceData(storageSpace)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setStorageSpaceData(storageSpace);
          _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }