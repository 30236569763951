var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.storageSpace.storageSpaceImagePath,
            _vm.storageSpace.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.storageSpace.storageSpaceImageIsDefault &&
          _vm.hasStorageDeleteImage},on:{"changeValue":function($event){_vm.storageSpace.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[(_vm.usersStatus)?_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":(_vm.id + "-userToken"),"errors":_vm.errors_mainToken,"value":_vm.storageSpace.mainToken,"options":_vm.userTokenOptions,"title":_vm.$t('Users.select'),"imgName":'users.svg'},on:{"changeValue":function($event){_vm.storageSpace.mainToken = $event}}}):_vm._e(),(_vm.placesStatus)?_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":(_vm.id + "-placeToken"),"errors":_vm.errors_mainToken,"value":_vm.storageSpace.mainToken,"options":_vm.placeTokenOptions,"title":_vm.$t('Places.select'),"imgName":'places.svg'},on:{"changeValue":function($event){_vm.storageSpace.mainToken = $event}}}):_vm._e(),(_vm.educationalGroupsStatus)?_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":(_vm.id + "-educationalGroupToken"),"errors":_vm.errors_mainToken,"value":_vm.storageSpace.mainToken,"options":_vm.educationalGroupTokenOptions,"title":_vm.$t('EducationalGroups.select'),"imgName":'EducationalGroups.svg'},on:{"changeValue":function($event){_vm.storageSpace.mainToken = $event}}}):_vm._e(),_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":"storageSpaceShowStatusTypeToken","errors":_vm.errors_storageSpaceShowStatusTypeToken,"value":_vm.storageSpace.storageSpaceShowStatusTypeToken,"options":_vm.showStatusTokenOptions,"title":_vm.$t('general.showStatus'),"imgName":'type.svg'},on:{"changeValue":function($event){_vm.storageSpace.storageSpaceShowStatusTypeToken = $event;
            _vm.$v.storageSpace.storageSpaceShowStatusTypeToken.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.storageSpace.fullCode,"title":_vm.$t('StorageSpaces.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.storageSpace.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceNameAr"),"errors":_vm.errors_storageSpaceNameAr,"value":_vm.storageSpace.storageSpaceNameAr,"title":_vm.$t('StorageSpaces.nameAr'),"imgName":'StorageSpaces.svg'},on:{"changeValue":function($event){_vm.storageSpace.storageSpaceNameAr = $event;
            _vm.$v.storageSpace.storageSpaceNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceNameEn"),"errors":_vm.errors_storageSpaceNameEn,"value":_vm.storageSpace.storageSpaceNameEn,"title":_vm.$t('StorageSpaces.nameEn'),"imgName":'StorageSpaces.svg'},on:{"changeValue":function($event){_vm.storageSpace.storageSpaceNameEn = $event;
            _vm.$v.storageSpace.storageSpaceNameEn.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceNameUnd"),"value":_vm.storageSpace.storageSpaceNameUnd,"title":_vm.$t('StorageSpaces.nameUnd'),"imgName":'StorageSpaces.svg'},on:{"changeValue":function($event){_vm.storageSpace.storageSpaceNameUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceDescriptionAr"),"errors":_vm.errors_storageSpaceDescriptionAr,"value":_vm.storageSpace.storageSpaceDescriptionAr,"title":_vm.$t('StorageSpaces.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.storageSpace.storageSpaceDescriptionAr = $event;
            _vm.$v.storageSpace.storageSpaceDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceDescriptionEn"),"errors":_vm.errors_storageSpaceDescriptionEn,"value":_vm.storageSpace.storageSpaceDescriptionEn,"title":_vm.$t('StorageSpaces.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.storageSpace.storageSpaceDescriptionEn = $event;
            _vm.$v.storageSpace.storageSpaceDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceDescriptionUnd"),"value":_vm.storageSpace.storageSpaceDescriptionUnd,"title":_vm.$t('StorageSpaces.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.storageSpace.storageSpaceDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceNotes"),"value":_vm.storageSpace.storageSpaceNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.storageSpace.storageSpaceNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }